@import "../../styles/breakpoints.scss";
@import "../../styles/variables.scss";

.main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  & span {
    font-weight: bold;
    line-height: $containerPaddingVertical;
  }
  padding-top: calc($containerPaddingVertical * 2);
  @include media('<=phone') {
    img {
      max-width: 80%;
    }
  }
}
img {
  border-radius: 4px;
  width: 440px;
  margin-top: 1em;
  @include media('<=phone') {
    max-width: 80%;
  }
}
.container-home {
  @include containerDimensions;
  padding-top: calc($containerPaddingVertical * 2);
  padding-bottom: calc($containerPaddingVertical * 2)
}
@import "../../styles/breakpoints.scss";
@import "../../styles/variables.scss";

$navbarSidesMargin: 40px;

.navbar {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  list-style-type: none;
  font-size: 16px;
  position:fixed;
  top:0;
  background-color: #000000;
  z-index: 1000;
  @include media('<=phone') {
    display: block;
    border-bottom: 1px solid #ffffff;
  }
  .logo {
    font-weight: bold;
    display: flex;
    margin-left: $navbarSidesMargin;
    white-space: nowrap;
    @include media('<=phone') {
      display: none;
    }
  }
  a {
    text-decoration: none;
    margin-left: 40px;
    justify-content: flex-start;
    text-align: right;
    @include media('<=phone') {
      margin-left:0px;
      margin-right:0px;
    }
  }
  &-link {
    cursor: pointer;
    &-container {
      width: 100%;
      display: block;
      text-align: right;
      padding-right: $navbarSidesMargin;
      @include media('<=phone') {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        justify-content: space-evenly;
        padding-right: 0px;
      }
    }
  }
}
.button-toggle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 20px;
  margin-left: 12px;
}
.dark {
  @include darkMode;
}
.light {
  @include lightMode;
}
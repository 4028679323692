@import "./styles/breakpoints.scss";
@import "./styles/variables.scss";

.App {
  text-align: center;
}
body {
  padding-top: 62.53px;
  margin: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}
.dark {
  background-color: #000000;
  color: #ffffff;
}
.light {
  background-color: #ffff;
  color: #000000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.section-title {
  font-size: 2em;
  font-weight: normal;
  padding-bottom: 1em;
}
.section-subtitle {
  font-size: 1em;
  font-weight: normal;
  padding-bottom: 1em;
}
@import "../../styles/breakpoints.scss";
@import "../../styles/variables.scss";

$transitionTime: 0.75s;

.ts {
  &-list {
    display:flex;
    justify-content: center;
  }
  &-wrapper {
    color: #000000;
    width: 80vmin;
    aspect-ratio: 70 / 30;
    display: flex;
    background-color: transparent;
    position: relative;
    border-radius: 4px;
    &-mobile {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-js {
    width: 10%;
    background-color: #edd431;
  }
  &-css {
    width: 10%;
    background-color: #2449d8;
  }
  &-html {
    width: 10%;
    background-color: #e44d27;
  }
  &-react {
    width: 10%;
    background-color: #62dafc;
  }
  &-git {
    width: 10%;
    background-color: #212121;
  }
  &-sql {
    width: 10%;
    background-color: #0079d6;
  }
  &-node{
    width: 10%;
    background-color: #84ce25;
  }
  &-firebase{
    width: 10%;
    background-color: #ffa816;
  }
  &-sass {
    width: 10%;
    background-color: #ce679a;
  }
  &-python {
    width: 10%;
    background-color: #d3d3d3;
  }
  &-box {
    opacity: 0;
    text-align: center;
    color: #ffffff;
    & p {
      background-color: #0000009e;
      padding: 3px 4px;
    }
    img {
      width: 64px;
      height: 64px;
      background-color: #ffffff;
    }
    &-mobile {
      flex-basis: 33.333333%;
      padding: 8px 0;
      & p {
        font-size: 12px;
      }
    }
  }
  &-item {
    display:flex;
    justify-content: center;
    align-items: center;
    transition: width $transitionTime;
    &:hover {
      width:28%;
      transition: width $transitionTime;
      .ts-box {
        opacity: 1;
        transition: opacity 1s;
      }
    }
  }
  &-msg {
    padding-bottom: 24px;
  }
}
.container-ts-desktop {
  @include media('<=tablet') {
    display: none;
  }
}
.container-ts-mobile {
  @include media('>tablet') {
    display: none;
  }
  img {
    width: 36px;
    height: 36px;
  }
}

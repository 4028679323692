@import "../../styles/breakpoints.scss";
@import "../../styles/variables.scss";

.container-about {
  @include containerDimensions;
  padding-bottom: calc($containerPaddingVertical * 2);
}
.about-me {
  padding-bottom: $containerPaddingVertical;
  &-paragraph {
    padding: 16px 0;
  }
}
@import "../../styles/breakpoints.scss";
@import "../../styles/variables.scss";

$fontSize: 20px;

.label-contact-form {
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
  padding: 32px 0;
  font-size: $fontSize;
  &-input {
    font-size: $fontSize;
    align-self: center;
    text-align: center;
    width: 300px;
    padding: 12px 0;
    border-radius: 8px;
    border: solid 2px #000000;
    font-family: $fontFamily;
    &:focus {
      outline: none;
    }
  }
  span {
    padding-bottom: 8px;
  }
}
.submit-contact-form {
  padding: 8px 16px;
  outline: none;
  background: transparent;
  font-size: $fontSize;
  font-family: $fontFamily;
  border-radius: 8px;
  cursor: pointer;
  border-style: solid;
  border-color: inherit;
  &:hover{
    border-color: $linkHoverColor;
    color: $linkHoverColor;
  }
}
.submit-div {
  padding: 32px 0px;
}
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

$linkHoverColor: #ff6961;
$transitionAnimation: 0.4s ease;
$containerMarginHorizontal: 15%;
$containerPaddingVertical: 36px;
$containerBorderBottomDark: 2px solid #ffffff;
$containerBorderBottomLight: 2px solid #000000;
$fontFamily: 'Courier Prime', 'Roboto', 'Arial';

@mixin darkMode {
  transition: $transitionAnimation;
  background-color: #000000;
  color: #ffffff;
  border-bottom: $containerBorderBottomDark;
  & :nth-child(5) {
    border-bottom: none;
  }
  a {
    color: #ffffff;
    &:hover {
      color: $linkHoverColor;
    }
  }
}
@mixin lightMode {
  transition: $transitionAnimation;
  background-color: #ffff;
  color: #000000;
  border-bottom: $containerBorderBottomLight;
  & :nth-child(5) {
    border-bottom: none;
  }
  a {
    color: #000000;
    &:hover {
      color: $linkHoverColor;
    }
  }
}
@mixin containerDimensions {
  padding: $containerPaddingVertical 0;
  margin: 0 $containerMarginHorizontal;
}